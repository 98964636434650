import React, {useEffect, useState} from 'react';
import {ClubLogo} from '../../ClubLogo';
import {PlayerImage} from '../../PlayerImg';

export const ScoreBox = ({
                             teams,
                             batting,
                             score,
                             overs,
                             probability,
                             scoregrid,
                             partnership,
                             displayPartnerShip = 'true',
                             prematch,
                             startdate,
                             complete,
                             eventStatus,
                             matchComment,
                             currentInnings,
                             runRate,
                         }) => {
    const rrrRegex = new RegExp("^.* require [0-9]+ runs from [0-9]+ balls*.$");
    const extractNumbersRegex = new RegExp(/\d+/g);

    if (partnership.bowler === '' && partnership.batter === '') {
        displayPartnerShip = 'false';
    }
    let noGrid = false;
    if (scoregrid.length <= 1) {
        noGrid = true;
    }
    const [winProbTitle, setWinProbTitle] = useState('Win probability');
    useEffect(() => {
        let intervalId;
        if (currentInnings == 2) {
            const updateTitle = () => {
                if (winProbTitle === 'Win probability') {
                    setWinProbTitle(matchComment);
                } else {
                    setWinProbTitle('Win probability');
                }
            };

            intervalId = setInterval(updateTitle, 20000);
        }
        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, [matchComment, winProbTitle, currentInnings]);
    const months = [
        'Jan',
        'Feb',
        'Mar',
        'Apr',
        'May',
        'Jun',
        'Jul',
        'Aug',
        'Sep',
        'Oct',
        'Nov',
        'Dec',
    ];
    let localdt = new Date(startdate ? startdate : '');
    let formattedDate = '';
    if (localdt !== '') {
        const day = localdt.getDate().toString().padStart(2, '0');
        const month = months[localdt.getMonth()];
        const year = localdt.getFullYear();
        const hours = localdt.getHours().toString().padStart(2, '0');
        const minutes = localdt.getMinutes().toString().padStart(2, '0');
        formattedDate = `${day} ${month} ${year} ${hours}:${minutes}`;
    }
    let reqRunRate;
    if (matchComment) {
        const regexResult = matchComment.match(rrrRegex);
        if (regexResult && regexResult.length > 0) {
            const numbersFromString = matchComment.match(extractNumbersRegex);
            if (numbersFromString && numbersFromString.length > 0) {
                const xRuns = numbersFromString[0];
                const remainingOvers = numbersFromString[1] / 6
                if (remainingOvers !== 0) {
                    reqRunRate = (xRuns / remainingOvers).toFixed(2);
                }
            }
        }
    }
    return (
        <div
            className={`flex flex-col w-full ${
                displayPartnerShip === 'true' ? 'h-[273px]' : 'h-[181px]'
            }`}
        >
            <div className='flex w-full h-[115px]'>
                <div
                    className={`flex-1 w-[50%] relative h-full ${
                        teams[1] === batting ? 'bg-opacity-90' : ''
                    } bg-white`}
                >
                    <div className='flex flex-col px-2 pt-1'>
                        <div className='flex justify-between'>
                            <ClubLogo teamName={teams[0]}/>
                            <div className='font-chakra bold text-[#1b062f] text-[26px]'>
                                {!prematch ? score[0] : '-'}
                            </div>
                        </div>
                        <div className='font-chakra bold text-[#1b062f] text-[14px] mt-2 truncate'>
                            {teams[0]}
                        </div>
                    </div>
                    {!prematch && (
                        <div className='absolute bottom-2 left-3 font-chakra normal-case text-[#1b062f] text-[14px]'>
                            {overs[0]} overs
                        </div>
                    )}
                    {teams[0] === batting && (
                        <div className='absolute top-0 right-0 w-[3px] h-[49px] bg-[#f73261]'/>
                    )}
                </div>
                <div
                    className={`flex-1 w-[50%] relative h-full ${
                        teams[0] === batting ? 'bg-opacity-90' : ''
                    } bg-white`}
                >
                    <div className='flex flex-col px-2 pt-1'>
                        <div className='flex justify-between'>
                            <ClubLogo teamName={teams[1]}/>
                            <div className='font-chakra bold text-[#1b062f] text-[26px]'>
                                {!prematch ? score[1] : '-'}
                            </div>
                        </div>
                        <div className='font-chakra bold text-[#1b062f] text-[14px] mt-2 truncate'>
                            {teams[1]}
                        </div>
                    </div>
                    {!prematch && (
                        <div className='absolute bottom-2 left-3 font-chakra normal-case text-[#1b062f] text-[14px]'>
                            {overs[1]} overs
                        </div>
                    )}
                    {teams[1] === batting && (
                        <div className='absolute top-0 right-0 w-[3px] h-[49px] bg-[#f73261]'/>
                    )}
                </div>
            </div>

            {/* Score Grid */}
            {!prematch
                ? !noGrid && (
                <div className='flex overflow-x-auto hide-scrollbar mt-1 min-h-[40px]'>
                    {scoregrid.map((item, index) => (
                        <div
                            className={`flex-shrink-0 px-2 py-1 mr-1 bg-white bg-opacity-15 h-[40px] min-w-[52px] flex justify-center items-center ${
                                index === 1 ? 'border-b-2 border-[#f73261]' : ''
                            }`}
                            key={index}
                        >
                  <span className='text-white text-[14px] font-chakra'>
                    {item}
                  </span>
                        </div>
                    ))}
                </div>
            )
                : startdate && (
                <div className='flex items-center justify-center h-[35px] bg-white bg-opacity-15 mt-1'>
              <span className='text-white text-[14px]'>
                Match starts {formattedDate}
              </span>
                </div>
            )}
            {displayPartnerShip === 'true' && (
                <>
                    <div className='flex gap-1 mt-1 h-[40px]'>
                        <div className='flex flex-1 items-center bg-white bg-opacity-15 p-1 relative'>
                            <div className='w-[50px] bg-white bg-opacity-15'>
                                <PlayerImage
                                    teamName={teams}
                                    playerName={partnership.bowler}
                                    width='40px'
                                    height='40px'
                                />
                            </div>
                            <div className='ml-2 flex-1'>
                                <div className='text-white text-[14px] font-chakra normal-case truncate'>
                                    {partnership.bowler?.split(' ')[1]}
                                </div>
                            </div>
                            <div className='text-right pr-2'>
                                <span className='text-white text-[14px] font-chakra normal-case'>
                                  {partnership.bowlerwickets}/{partnership.bowlerruns} [
                                    {partnership.bowlerover}]
                                </span>
                            </div>
                            <div className={`absolute right-0 bg-[#f73261] w-[3px] h-[40px]`}/>
                        </div>

                        <div className='flex flex-1 items-center bg-white bg-opacity-15 p-1 relative'>
                            <div className='w-[50px] bg-white bg-opacity-15'>
                                <PlayerImage
                                    teamName={teams}
                                    playerName={partnership.batter}
                                    width='40px'
                                    height='40px'
                                />
                            </div>
                            <div className='ml-2 flex-1'>
                                <div className='text-white text-[14px] font-chakra normal-case truncate'>
                                    {partnership.batter?.split(' ')[1]}
                                </div>
                            </div>
                            <div className='text-right pr-2'>
                                <span className='text-white text-[14px] font-chakra normal-case'>
                                  {partnership.batterruns}
                                </span>
                                <span className='text-white text-[14px] font-chakra normal-case'>
                                    {' '}({partnership.batterballs})
                                </span>
                            </div>
                            <div className={`absolute right-0 bg-[#f73261] w-[3px] h-[40px]`}/>
                        </div>
                    </div>
                    <div className='flex gap-1 mt-1 h-[40px]'>
                        <div className='flex flex-1 items-center bg-white bg-opacity-15 p-1 relative'>
                            <div className='w-[50px] bg-white bg-opacity-15'>
                                <PlayerImage
                                    teamName={teams}
                                    playerName={partnership.bowler2}
                                    width='40px'
                                    height='40px'
                                />
                            </div>
                            <div className='ml-2 flex-1'>
                                <div className='text-white text-[14px] font-chakra normal-case truncate'>
                                    {partnership.bowler2?.split(' ')[1]}
                                </div>
                            </div>
                            <div className='text-right pr-2'>
                                <span className='text-white text-[14px] font-chakra normal-case'>
                                  {partnership.bowlerwickets2}/{partnership.bowlerruns2} [
                                    {partnership.bowlerover2}]
                                </span>
                            </div>
                            <div className={`absolute right-0 w-[3px] h-[40px]`}/>
                        </div>

                        <div className='flex flex-1 items-center bg-white bg-opacity-15 p-1 relative'>
                            <div className='w-[50px] bg-white bg-opacity-15'>
                                <PlayerImage
                                    teamName={teams}
                                    playerName={partnership.batter2}
                                    width='40px'
                                    height='40px'
                                />
                            </div>
                            <div className='ml-2 flex-1'>
                                <div className='text-white text-[14px] font-chakra normal-case truncate'>
                                    {partnership.batter2?.split(' ')[1]}
                                </div>
                            </div>
                            <div className='text-right pr-2'>
                                <span className='text-white text-[14px] font-chakra normal-case'>
                                  {partnership.batterruns2}
                                </span>
                                <span className='text-white text-[14px] font-chakra normal-case'>
                                  {' '}({partnership.batterballs2})
                                </span>
                            </div>
                            <div className={`absolute right-0 w-[3px] h-[40px]`}/>
                        </div>
                    </div>
                </>
            )}

            {(runRate && reqRunRate) && (
                <div className='flex gap-1 mt-1 h-[40px]'>
                    <div className='flex flex-1 gap-2 justify-center items-center bg-white bg-opacity-15 p-1 relative'>
                        <span
                            className='font-chakra normal-case'
                            style={{color: '#32F78D'}}
                        >
                            Run rate: {runRate}
                        </span>
                        <span>•</span>
                        <span className='font-chakra normal-case'>
                            Req. run rate: {reqRunRate}
                        </span>
                    </div>
                </div>
            )}
        </div>
    );
};
export default ScoreBox;
